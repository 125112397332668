import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import axios from "axios"

class ContactPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form : {   
                name: '',
                email :'',
                message: ''
            },
            error : {
                name: false,
                email :false,
                message: false,
            },
            msgError : false,
            msgSuccess : false,
            loading : false
        }; 
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;


        let formCopy = JSON.parse(JSON.stringify(this.state.form))
        formCopy[name] = value;
    
        this.setState({
            form:formCopy 
        }) 
        

    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendEmail = (e) =>  {
        
        this.setState({
            msgError : false,
            msgSuccess : false,
            loading:true
        })
        e.preventDefault();

       
        let nameError = (this.state.form.name === '') ? true : false;
        let emailValid = this.validateEmail(this.state.form.email);
        let messageError = (this.state.form.message === '') ? true : false;

    
        this.setState(prevState => ({
            error: {                   // object that we want to update
                ...prevState.error,    // keep all other key-value pairs
                name: nameError,
                email: !emailValid,
                message: messageError,
            }
        }))
        
       
        if(nameError || messageError || !emailValid) {
           
            this.setState({
                msgError : true,
                msgSuccess : false,
                loading:false
            })
       } else {
            axios.post(`https://api.app.uxer.fr/public/api/sendmailvirginia`, this.state.form)
            .then(res => {
                this.setState({
                    msgError : false,
                    msgSuccess : true,
                    loading:false
                })
            })
       }
    }

    render() {
        return (
        <Layout>
            <SEO 
            title="Contacter une graphiste illustratrice sur Bordeaux"
            description="Vous recherchez un graphiste, un illustrateur ? Contactez Virginia pour des Illustrations, dessins et créations graphiques originales et uniques"
            />
            <div className="l-contact">
                <div className="container"> 
                    <p className="l-contact__title">On discute ?</p>
                    <div className="l-contact__content">
                        <h1 className="l-contact__content__title">Me contacter</h1>
                    </div>
                    <div className="l-contact__content__wrapper">
                        <div className="l-contact__content__item">
                            <p className="l-contact__content__item__title">Illustration</p>
                            <p className="l-contact__content__item__text">Dans le cadre de mon travail d’illustratrice, je suis représentée par l’agence Patricia Lucas pour la publicité, la presse, l'édition et le web.</p>
                            <div className="l-contact__content__item__infos">
                                <div className="l-contact__content__item__infos__wrapper">
                                    <div className="l-contact__content__item__infos__item">
                                        <p className="l-contact__content__item__infos__item__text">
                                            Patricia Lucas<br/>
                                            Agent d'illustrateurs<br/>
                                            38 rue Legendre 75017 Paris
                                        </p>
                                    </div>
                                    <div className="l-contact__content__item__infos__item">
                                        <p className="l-contact__content__item__infos__item__text">
                                            +33 (0)1 48 74 18 05<br/>
                                            +33 (0)6 62 30 99 63<br/>
                                            www.patricia-lucas.com<br/>
                                            <a href="mailto:patricia@patricia-lucas.com">patricia@patricia-lucas.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="l-contact__content__item__picture">
                                <Img fluid={this.props.data.illustration.childImageSharp.fluid} />
                            </div>
                        </div>
                        <div className="l-contact__content__item">
                            <p className="l-contact__content__item__title">Tatouages</p>
                            <p className="l-contact__content__item__text">Pour un projet de tatouage, merci de me contacter uniquement par mail,<br/>à l’adresse suivante :   
                            <a href="mailto:v.garridomillan@gmail.com"> v.garridomillan@gmail.com</a></p>
                            {/* <div className="l-contact__content__item__form">
                                <form onSubmit= {(e) => this.sendEmail(e)} className="m-form">
                                    <div className="m-form__group">
                                        <div className="m-form__item">
                                            <label className="m-form__label">Votre nom</label>
                                            <input type="text" name="name" onChange={this.handleChange} 
                                            className={ (this.state.error.name) ? 'm-form__input m-form__input--alert' : 'm-form__input'}
                                            
                                            />
                                            {
                                                this.state.error.name && 
                                                <p className="m-form__alert">Ce champ est requis</p>
                                            }
                                        </div>
                                        <div className="m-form__item">
                                            <label className="m-form__label">Votre adresse mail</label>
                                            <input 
                                            type="text" name="email" 
                                            onChange={this.handleChange} 
                                            className={ (this.state.error.email) ? 'm-form__input m-form__input--alert' : 'm-form__input'}
                                            
                                            />
                                            {
                                                this.state.error.email && 
                                                <p className="m-form__alert">L’adresse mail n’est pas valide</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="m-form__group">
                                        <div className="m-form__item">
                                            <label className="m-form__label">Votre message</label>
                                            <textarea name="message" onChange={this.handleChange} 
            
                                            className={ (this.state.error.message) ? 'm-form__input m-form__input--area m-form__input--alert' : 'm-form__input m-form__input--area'}
                                            >

                                                
                                            </textarea>
                                            {
                                                this.state.error.message && 
                                                <p className="m-form__alert">Ce champ est requis</p>
                                            }
                                        </div>
                                    </div>
                                    <button className="m-form__button">
                                        <span>Envoyer</span>
                
                                        {this.state.loading && (
                                            <div className="spinner">
                                                <div className="double-bounce1"></div>
                                                <div className="double-bounce2"></div>
                                            </div>
                                        )}
                                    </button> 
                                    {this.state.msgSuccess && 
                                    (
                                        <p className="m-form__success">Message envoyé</p>
                                    )
                                    }
                                </form>
                            </div> */}
                            <div className="l-contact__content__item__picture">
                                <Img fluid={this.props.data.tatouage.childImageSharp.fluid} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        )
    }
   
  
}
  
export default ContactPage


export const query = graphql`
  query {
    illustration:file(relativePath: { eq: "contact/illustration.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 708, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    tatouage:file(relativePath: { eq: "contact/tatouage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 708, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  },
`  